
import Vue from "vue";
import { BillingClient } from "@/lib/clients";
import { CreatePortalSessionInput } from "@/lib/models/billing";

const billingClient = new BillingClient();

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    async onSubmit() {
      const payload: CreatePortalSessionInput = {
        returnUrl: `${window.location.origin}/subscription/modify`,
      };
      const session = await billingClient.createPortalSession(payload);

      window.location.href = session.url;
    },
  },
});
