var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "plan-info section" }, [
      _c("p", [_vm._v("現在のご利用プラン")]),
      _vm.billingInfo
        ? _c("table", { staticClass: "plan" }, [
            _c("thead", [
              _c("tr", [
                _vm._m(0),
                _c("th", [
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.planName)),
                  ]),
                ]),
                _c("th", [
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.planPrice)),
                  ]),
                  _c("span", { staticClass: "yen" }, [_vm._v("円")]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("table", { staticClass: "subscription" }, [
        _c("tbody", [
          _c("tr", [
            _vm._m(1),
            _vm._m(2),
            _vm.billingInfo
              ? _c("td", [
                  _c("span", [
                    _vm._v(" " + _vm._s(_vm.planCreated ?? "------") + " "),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("tr", [
            _vm._m(3),
            _vm._m(4),
            _vm.billingInfo
              ? _c("td", [
                  _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.planCurrentPeriodEnd ?? "------") + " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "payment-info section" },
      [
        _c("p", [_vm._v("決済情報")]),
        _c("table", [
          _c("tbody", [
            _c("tr", [
              _vm._m(5),
              _vm._m(6),
              _vm.billingInfo
                ? _c("td", [
                    _vm.billingInfo.platform
                      ? _c("span", [_vm._v(" App内課金 ")])
                      : _c("span", [_vm._v(" クレジットカード決済 ")]),
                  ])
                : _vm._e(),
            ]),
            _c("tr", [
              _vm._m(7),
              _vm._m(8),
              _vm.billingInfo
                ? _c("td", [
                    _vm.billingInfo.platform
                      ? _c("span", [_vm._v(" XXXX XXXX XXXX ---- ")])
                      : _c("span", [
                          _vm._v(
                            " XXXX XXXX XXXX " +
                              _vm._s(_vm.billingInfo.cardInfo.last4) +
                              " "
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]),
            _c("tr", [
              _vm._m(9),
              _vm._m(10),
              _vm.billingInfo
                ? _c("td", [
                    _vm.billingInfo.platform
                      ? _c("span", [_vm._v(" ------ ")])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.billingInfo.cardInfo.exp_year) +
                              "年 " +
                              _vm._s(_vm.billingInfo.cardInfo.exp_month) +
                              "月 "
                          ),
                        ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm.billingInfo && !_vm.billingInfo.platform
          ? _c(
              "router-link",
              {
                staticClass: "button-design modify",
                attrs: { to: { name: "modifyPayment" } },
              },
              [_vm._v(" 決済情報を編集する ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "unsubscription section" },
      [
        _c("p", [_vm._v("プレミアム会員を退会する")]),
        _vm._m(11),
        _c(
          "router-link",
          {
            staticClass: "button-design unsubscription",
            attrs: { to: { name: _vm.navigatePath } },
          },
          [_vm._v(" 退会する ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("th", [
      _c("img", { attrs: { src: require("@/assets/img/icon/premium.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("プラン登録日")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("：")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("次回更新日")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("：")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("決済手段")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("：")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("カード番号")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("：")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("有効期限")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("td", [_c("strong", [_vm._v("：")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "description" }, [
      _c("div", [
        _vm._v(
          "Nolaのプレミアム会員を退会する場合は、下記のボタンより退会手続きをお願いいたします。"
        ),
      ]),
      _c("div", { staticClass: "margin-bottom" }, [
        _vm._v(
          " なお、有料機能の拡張箇所に保存しているデータは、無料会員になると編集することはできませんが、閲覧することは可能ですのでご安心ください。 "
        ),
      ]),
      _c("div", [
        _vm._v(
          "下記のボタンを押して退会の注意事項をご確認の上、プレミアム会員の退会をお願いいたします。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }