
import Vue from "vue";
import { Auth } from "aws-amplify";
import { callApi } from "@/lib/api";
import {
  StripeElements,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import gql from "graphql-tag";

import { BillingClient } from "@/lib/clients";
import { RegisterCustomerInput } from "@/lib/models/billing";
import { showNotifyDialog } from "../../../lib/dialog";

const billingClient = new BillingClient();

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      prepared: false,
      availableCreditCard: false,
      errorMessage: null,
    };
  },

  async mounted() {
    const { stripeElements } = this;

    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    const classes = {
      focus: "focused",
      empty: "empty",
      invalid: "invalid",
    };

    this.cardNumber = stripeElements.create("cardNumber", {
      style,
      classes,
      placeholder: "XXXX XXXX XXXX XXXX",
      showIcon: true,
    });
    this.cardNumber.mount("#card-number");
    this.cardExpiry = stripeElements.create("cardExpiry", {
      style,
      classes,
      placeholder: "MM / YY",
    });
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc = stripeElements.create("cardCvc", {
      style,
      classes,
      placeholder: "XXX",
    });
    this.cardCvc.mount("#card-cvc");

    this.prepared = true;
  },
  beforeDestroy() {
    this.cardNumber!.destroy();
    this.cardExpiry!.destroy();
    this.cardCvc!.destroy();
  },
  computed: {
    stripeElements() {
      const { $stripe } = this;
      return $stripe.elements({ locale: "ja" });
    },
  },
  methods: {
    async register() {
      const { $stripe } = this;
      this.errorMessage = null;
      const user = await Auth.currentUserPoolUser().catch((err) => false);
      try {
        const token = await $stripe.createToken(this.cardNumber!);
        const verificationToken = await $stripe.createToken(this.cardNumber!);

        if (token.error) {
          this.errorMessage = token.error.message || null;
          return false;
        }

        const payload: RegisterCustomerInput = {
          token: token.token.id,
          verificationToken: verificationToken.token!.id,
          email: user.attributes.email || user.username,
        };

        return await billingClient.registerCustomer(payload);
      } catch (error: any) {
        const msg = error.errors[0].extensions.exception.forCustomerMessage || "カード登録エラーが発生しました。";
        await showNotifyDialog({
          title: "クレジットカード登録エラー",
          content: msg,
        });
        return false;
      }
    },
    async deleteCard(event) {
      event.preventDefault();
      const res = await callApi(gql`
        mutation mutation {
          deleteCard {
            result
          }
        }
      `);
      this.$emit("cardDelete", res);
    },
  },
});

interface Props {}

interface Data {
  cardNumber: StripeCardNumberElement | null;
  cardExpiry: StripeCardExpiryElement | null;
  cardCvc: StripeCardCvcElement | null;
  prepared: boolean;
  availableCreditCard: boolean;
  errorMessage: string | null;
}

interface Computed {
  stripeElements: StripeElements;
}

interface Methods {
  register(): Promise<boolean>;
  deleteCard(event: Event): void;
}
